export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Master Data',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Satker',
        route: 'Satker | Executive',
      },
      {
        title: 'Personil',
        route: 'Personil | Executive',
      },
      {
        title: 'Jajaran',
        route: 'Polres Jajaran | Executive',
      },
      {
        title: 'Darurat',
        route: 'Darurat | Executive',
      },
      {
        title: 'Kegiatan',
        route: 'Kegiatan | Executive',
      },
      {
        title: 'Kejadian',
        route: 'Kejadian | Executive',
      },
    ],
  },
]
